export const STORE_ID = "1";
export const WEB_URL = "https://onkartraders.devminds.co.in/";
// export const WEB_URL = "http://localhost:3000/";
// export const API_BASE_URL = "http://localhost:8080/";
// export const IMG_BASE_URL = "http://localhost:8080/Images/";
export const API_BASE_URL = "https://onkartradersapi.devminds.co.in/";
export const IMG_BASE_URL = "https://onkartradersapi.devminds.co.in/Images/";
export const defaultMainAttribute =  3;
export const defaultAttribute = 13;
export const defaultCategory = 16;
export const defaultSubCategory = 71;