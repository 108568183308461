
export function calculateGStPrice(
    orderQty: number, 
    gst_percentage: number = 0, 
    attributes: any, 
    unitvalue: any, 
    units: any[]
  ) {
    // Use attributes.unit if unitvalue is undefined or null
    const selectedUnitValue = unitvalue ?? attributes.unit;
  
    // Find unit details based on the selected unit value
    const unit_details = units && units.length > 0 
      ? units.filter((e: any) => e.unit === selectedUnitValue) 
      : [];
  
    const conversion_factor = unit_details && unit_details.length > 0 
      ? Number(unit_details[0].conversion_factor) 
      : 1;
  
    // Calculate the base price per unit after applying the conversion factor
    const base_price_per_unit = Number(attributes.price_per_unit);
    const base_mrpPrice_per_unit = Number(attributes.mrpPrice_per_unit);
    // Apply the conversion factor and manage decimal precision
    const converted_price_per_unit = parseFloat((conversion_factor * base_price_per_unit).toFixed(4));
    const converted_mrpPrice_per_unit = parseFloat((conversion_factor * base_mrpPrice_per_unit).toFixed(4));
    // Calculate GST per unit price and manage decimal precision
    const gst_per_price = parseFloat((converted_price_per_unit * (gst_percentage / 100)).toFixed(4));
    const gst_price_total = parseFloat((gst_per_price * orderQty).toFixed(4));
  
    // Calculate final prices including GST
    const final_price = parseFloat((converted_price_per_unit + gst_per_price).toFixed(4));
    const final_mrpPrice = parseFloat((converted_mrpPrice_per_unit + gst_per_price).toFixed(4));
  
    // Update the attributes object with calculated prices
    attributes.gst_percentage = gst_percentage;
    attributes.gst_per_price = gst_per_price;
    attributes.gst_price_total = gst_price_total;
    attributes.mrpPrice = final_mrpPrice;
    attributes.price = final_price;
    return attributes;
  }
  
  

  export function convertNumberToWords(num: number): string {
    const a: string[] = [
        '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven',
        'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const b: string[] = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const convertHundreds = (n: number): string => {
        if (n === 0) return '';
        if (n < 20) return a[n] + ' ';
        if (n < 100) return b[Math.floor(n / 10)] + ' ' + a[n % 10] + ' ';
        return a[Math.floor(n / 100)] + ' Hundred ' + convertHundreds(n % 100);
    };

    const convertToWords = (n: number): string => {
        if (n === 0) return 'Zero';

        let output = '';

        if (Math.floor(n / 100000) > 0) {
            output += convertHundreds(Math.floor(n / 100000)) + 'Lakh ';
            n %= 100000;
        }

        if (Math.floor(n / 1000) > 0) {
            output += convertHundreds(Math.floor(n / 1000)) + 'Thousand ';
            n %= 1000;
        }

        output += convertHundreds(n);
        return output.trim();
    };

    // Split the number into integer and fractional part
    const integerPart = Math.floor(num);
    const fractionalPart = Math.round((num - integerPart) * 100); // for two decimal places

    let result = convertToWords(integerPart);

    if (fractionalPart > 0) {
        result += ' and ' + convertToWords(fractionalPart) + ' Paise';
    }

    return result + ' Only';
}


export const formatProductName = (name:string) => {
  let a = name;
  a = a.replace(/\b(LESS5|W|G|ABOVE5)\b/g, '').trim();
  return a;
}

export function formatDate(timestamp:number) {
  const date = new Date(timestamp);
  return date.toISOString().split('T')[0]; // Returns 'yyyy-MM-dd'
}

export function getYearRange(timeStamp: number): string {
  const currentDate = new Date(timeStamp);
  const currentYear: number = currentDate.getFullYear();
  const currentMonth: number = currentDate.getMonth(); // 0 is January, 1 is February, etc.

  // Extract the last two digits of the year
  const shortYear = (year: number): string => year.toString().slice(-2);

  // Define the months for the first range (January, February, March, April)
  const firstRangeMonths: number[] = [0, 1, 2, 3];

  // Check if the current month is within the first range
  if (firstRangeMonths.includes(currentMonth)) {
    return `${shortYear(currentYear - 1)}-${shortYear(currentYear)}`;
  } else {
    return `${shortYear(currentYear)}-${shortYear(currentYear + 1)}`;
  }
}

export function customRoundOff(a:number) {
  // Separate the integer and decimal parts
  let integerPart = Math.floor(a);
  let decimalPart = a - integerPart;

  let totalPrice;
  if (decimalPart <= 0.5) {
      totalPrice = integerPart; // Round down for decimals 0.1 to 0.5
  } else {
      totalPrice = integerPart + 1; // Round up for decimals 0.6 to 0.9
  }

  let roundoff = parseFloat((totalPrice - a).toFixed(2)); // Calculate the roundoff value
  return { a, roundoff, totalPrice };
}