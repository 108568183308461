import React,{useRef} from 'react';
import { styled } from '@mui/system';
import ReactToPrint from "react-to-print";
import {Box,Button} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import InvoiceHeaderComp from "./Invioce/InvoiceHeaderComp";
import InvoiceDetailComp from "./Invioce/InvoiceDetailComp";
import { useParams } from 'react-router-dom';

const InvoiceContainer = styled("div")`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
`;

const InvoiceHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
`;

const InvoiceTitle = styled("h1")`
  margin: 0;
  font-size: 36px;
`;

const InvoiceTo = styled("div")`
  background-color: #ffb000;
  color: #fff;
  padding: 10px;
  text-align: right;
`;

const BrandSection = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffb000;
  padding: 20px;
  color: #fff;
  margin-bottom: 20px;
`;

const BrandName = styled("div")`
  font-size: 1.5em;
  font-weight: bold;
`;

const InvoiceDetails = styled("div")`
  text-align: right;
`;

const InvoiceTable = styled("table")`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th, td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #333;
    color: #fff;
    font-weight: bold;
  }

  td {
    background-color: #fff;
  }
`;

const TermsConditions = styled("div")`
  font-size: 0.85em;
  margin-top: 20px;
  color: #333;
`;

const PaymentInfo = styled("div")`
  background-color: grey;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
`;

const Footer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  background-color: grey;
  color: #fff;
  font-size: 0.85em;
`;

const TotalAmount = styled("div")`
  text-align: right;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
`;

const Invoice = ({ data ,order}: any) => {
  const { orderId } = useParams();
  const invoiceRef = useRef<HTMLDivElement>(null);

  const calculateGrandTotal = () => {
    return data.reduce((total: any, item: any) => {
      return total + item.attribute.price * item.orderQty;
    }, 0);
  };
  const calculateGSTTotal = () => {
    return data.reduce((total: any, item: any) => {
      return total + item.attribute.gst_price_total ;
    }, 0);
  };
  
  return (
    <InvoiceContainer>
    <ReactToPrint
      trigger={() => (
        <Box sx={{"justifyContent": "end",display: "flex"}}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
                sx={{ marginBottom: '20px',width:"170px", backgroundColor: '#ffb000', '&:hover': { backgroundColor: '#ffa500' } }}
              >
                Print Invoice
              </Button>
        </Box>
        
      )}
        content={() => invoiceRef.current}
      />
         <div  ref={invoiceRef}>
          <InvoiceHeaderComp id={orderId ? orderId : order.orderId  } orderDate={order.orderDate}
          />
          <InvoiceDetailComp data={data} calculateGrandTotal={calculateGrandTotal}
          calculateGSTTotal={calculateGSTTotal} order={order}
          />
         </div>



    {/* <InvoiceContainer ref={invoiceRef}>
      <InvoiceHeader>
        <InvoiceTitle>INVOICE</InvoiceTitle>
      </InvoiceHeader>

      <BrandSection>
        <BrandName>Brand Name</BrandName>
        <InvoiceTo>
          <p>Invoice to:</p>
          <p>Dwyane Clark</p>
          <p>24 Dummy Street Area, Location, Lorem Ipsum, 570XX59</p>
        </InvoiceTo>
      </BrandSection>

      <InvoiceDetails>
        <p>Invoice#: 52148</p>
        <p>Date: {new Date().toLocaleDateString()}</p>
      </InvoiceDetails>

      <InvoiceTable>
        <thead>
          <tr>
            <th>SL.</th>
            <th>Item Description</th>
            <th>Price</th>
            <th>Qty.</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any, index: any) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.name} <br/>{item.attribute.mainAttributeName}:  {item.attribute.attributeName}</td>
              <td>₹{item.attribute.price.toFixed(2)}</td>
              <td>{item.orderQty}</td>
              <td>₹{(item.attribute.price * item.orderQty).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </InvoiceTable>

      <TermsConditions>
        <p>Terms & Conditions: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dignissim pretium consectetur.</p>
      </TermsConditions>

      <TotalAmount>Total: ₹{calculateGrandTotal().toFixed(2)}</TotalAmount>


      <Footer>
        <p>8484848484 | Kolhapur Maharashtra </p>
        <p>Thank you for your business</p>
      </Footer>
    </InvoiceContainer> */}
    </InvoiceContainer>
    
  );
};

export default Invoice;
