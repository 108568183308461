import React, { useState, useEffect } from 'react';
import { Button, Box, Grid, Typography, Dialog,DialogActions,DialogTitle,FormControl,
  Select,MenuItem,InputLabel,TextField
 } from '@mui/material';
import { Container,FormDiv,ButtonCust } from "../styles/Product.style";
import {BUTTON_STYLE, HEADING_COLOR,CANCEL_BUTTON_STYLE} from "../../../../constants/style"
import { useNavigate } from 'react-router-dom';
import ProductSearch from "./ProductSearch";
import OrderProductBox from "./OrderProductBox";
import {create_order,get_units,get_gst_rates} from "../services/order";
import CustomizedSnackbars from "../../../reusable/CustomizedSnackbars";
import DialogInvoice from './DialogInvoice';
import GstSwitch from "./GstSwitch";
import {calculateGStPrice, formatDate, customRoundOff} from "../constants/index";
import UserSearch from "./UserSearch";

const OrderForm: React.FC = () => {
  const [errorMessage,setErrorMessage] = useState("");
  const [withGst,setWithGst] = useState(2);
  const [status,setStatus] = useState("Pending");
  const [units,setUnits] = useState([]);
  const [vehicleNo,setvehicleNo] = useState("");
  const [totalPrice,settotalPrice] = useState<any>();
  const [roundOff,setroundOff] = useState<any>();
  const [openInvoiceDailog, setOpenInvoiceDailog] = React.useState(false);
  const [orderProducts, setOrderProducts ] = useState<any>([]);
  const [selectedUserId,setSelectedUserId] = useState<any>({});
  const navigate = useNavigate();
  const [openError, setOpenError] = useState(false);
  const [ openSnackBars,setOpenSnackBars] = useState(false);
  const [gstRates,setGstRates] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [orderDate, setOrderDate] = useState<string | null>(formatDate(new Date().getTime()));
  useEffect(()=>{
    fetchUnits();
    fetchGstRates();
  },[]);

  const onSelectToOrder = (product:any) => {
    setErrorMessage("")
    let exitingProduct  = orderProducts.find((e:any)=>e.attribute.id == product.attribute.id)
    if(!exitingProduct){
      let attributes = product.attribute;
      attributes.unit = attributes.base_unit;
      attributes.price_per_unit = attributes.price;
      let newProduct: any = { ...product,attribute:attributes }; // Create a copy of the product
      newProduct.orderQty = 1;
      if(withGst === 1){
        let gst_percentage = product.attribute.product_init_gst_per ? product.attribute.product_init_gst_per : 0;
        let newObj = calculateGStPrice(newProduct.orderQty,gst_percentage,newProduct.attribute,newProduct.attribute.unit,units)
        newProduct.attribute = newObj;
      }
      let updatedOrderProducts = [...orderProducts,newProduct]; // Copy the existing orderProducts array
      setOrderProducts([...updatedOrderProducts]); 
    }
    
  }
  const updateOrderQty = (index: number, newQty: number) => {
    const updatedProducts = [...orderProducts];
    let e =  { ...updatedProducts[index],  orderQty: newQty };
    let newObj = calculateGStPrice(newQty,e.attribute.gst_percentage,e.attribute,e.attribute.unit,units)
        e.attribute = newObj;
    updatedProducts[index] = e;
    setOrderProducts(updatedProducts);
  };
  const deleteOrderedProduct = (attributeid:number)=>{
    let data = orderProducts.filter((e:any) => e.attribute.id != attributeid)
      setOrderProducts([...data])
  }
  const handleCloseError = () => {
    setOpenError(false);
  };
  const fetchUnits = async () => {
      try{
         const response:any = await get_units();
         setUnits(response.data?.data)
      }catch(error){
        
      }
  }
  const fetchGstRates = async () => {
    try{
       const response:any = await get_gst_rates();
       setGstRates(response.data?.data)
    }catch(error){
      
    }
}
  
  const onUnitChange = (product: any, unitvalue: any) => {
    // let unit_details: any = units && units.length > 0 ? 
    //                           units.filter((e: any) => e.unit == unitvalue) : [];
    
    // let conversion_factor: number = unit_details && unit_details.length > 0 ? 
    //                                 Number(unit_details[0].conversion_factor) : 1;
    
    let updatedProducts = [...orderProducts];
    updatedProducts = updatedProducts.map((e: any) => {
      if (e.id === product.id) {
        e.attribute.unit = unitvalue;
        let orderQty: number = (e.attribute.unit !== e.attribute.base_unit ) && e.orderQty < 100 ?  
                             100 : Number(e.orderQty);
        // let price_per_unit: number = Number(e.attribute.price_per_unit);
        // let mrpPrice: number = Number(e.attribute.mrpPrice);
        e.orderQty = orderQty;
        let newObj = calculateGStPrice(e.orderQty,e.attribute.gst_percentage,e.attribute,e.attribute.unit,units)
        e.attribute = newObj;
        // // Ensure that price is assigned as a number
        // e.attribute.price = parseFloat((conversion_factor * price_per_unit).toFixed(2));
        // e.attribute.mrpPrice = parseFloat((conversion_factor * mrpPrice).toFixed(2));       
      }
      return e;
    });
  
    setOrderProducts([...updatedProducts]);
  };
  const onGstChange  = (product: any, value: any) => {
    
    let gst_percentage = value;
    let updatedProducts = [...orderProducts];
    updatedProducts = updatedProducts.map((e: any) => {
      if (e.id === product.id) {
        let newObj = calculateGStPrice(e.orderQty,gst_percentage,e.attribute,e.attribute.unit,units)
        e.attribute = newObj;
        return e
      }
      return e;
    });
  
    setOrderProducts([...updatedProducts]);
  };
  const onPriceChange = (product: any, value: any) => {
    let updatedProducts = [...orderProducts];
    updatedProducts = updatedProducts.map((e: any) => {
      if (e.id === product.id) {
        let newAttri= e.attribute
        newAttri.price_per_unit = value
        newAttri.mrpPrice_per_unit = value
        let newObj = calculateGStPrice(e.orderQty,e.attribute.gst_percentage,newAttri,e.attribute.unit,units)
        e.attribute = newObj;
        return e
      }
      return e;
    });
  
    setOrderProducts([...updatedProducts]);
  };
  const createOrderFun = async () => {
    if(orderProducts && orderProducts.length > 0 && selectedUserId && selectedUserId.id > 0 && orderDate ){
      setErrorMessage("")
      let totalPriceWithOutRoundOff:number = orderProducts.reduce((total:any, product:any) => Number(total) + 
      (Number(product.attribute.price) * Number(product.orderQty)), 0);
      let finalAmt = customRoundOff(totalPriceWithOutRoundOff);
      settotalPrice( finalAmt.totalPrice > 0 ? finalAmt.totalPrice : totalPriceWithOutRoundOff);
      setroundOff(finalAmt.roundoff)
      let payload = {
        "order": {
          "status":status,
          "totalQty": orderProducts.reduce((total: any, product: any) => {
            // Check if base_unit and unit are different
            const orderQty = product.attribute.base_unit !== product.attribute.unit ? 1 : Number(product.orderQty);
            return Number(total) + orderQty;
        }, 0),
           "orderDate": orderDate ? ""+new Date(orderDate).getTime()+"" : "",
           "totalPriceWithOutRoundOff":totalPriceWithOutRoundOff,
          "totalPrice": finalAmt.totalPrice > 0 ? finalAmt.totalPrice : totalPriceWithOutRoundOff,
          "roundOff":finalAmt.roundoff ,
          "vehicleNo":vehicleNo,
          "withGst":withGst,
          "userId":selectedUserId.id
        },
        "orderProducts": orderProducts.map((product:any) => ({
          "productId": product.attribute.productId,
          "attributeId": product.attribute.attributeId,
          "mainAttributesId": product.attribute.mainAttributesId,
          "qty": product.orderQty,
          "mrpPrice": product.attribute.mrpPrice,
          "discount": product.attribute.discount,
          "base_unit": product.attribute.base_unit,
          "unit":product.attribute.unit,
          "price_per_unit":product.attribute.price_per_unit,
          "price": product.attribute.price,
          "totalPrice": product.attribute.price * product.orderQty,
          "rackPlacementId": product.attribute.rackPlacementId || null,
          "gst_per_price": product.attribute.gst_per_price,
          "gst_percentage": product.attribute.gst_percentage,
          "gst_price_total": product.attribute.gst_per_price * product.orderQty,
        }))
      };
       const orderInsertData:any = await create_order(payload);
       setOrderId(orderInsertData?.data?.orderId)
       setOpenInvoiceDailog(true)
       setOpenSnackBars(true);
      //  setOrderProducts([])
    }else{
      setOrderId("");
      setErrorMessage("Please select user and product for create order.")
    }
  }
  const handleOrderDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setOrderDate(event.target.value);
      };
  return (
    <Container>
      <CustomizedSnackbars
      message = {"Order created successfully."} 
      openSnackBars={openSnackBars} 
      setOpenSnackBars={setOpenSnackBars}
      />
      <FormDiv>
      <Typography>Create Order</Typography><br/>
        <div>
           <Grid item sm={12} md={12}>
            <UserSearch 
              selectedUserId={selectedUserId}
              setSelectedUserId={setSelectedUserId} /> 
           </Grid>
   </div> <br/>
           <div>
         
           <Grid item sm={12} md={12}>
           <ProductSearch onSelectToOrder={onSelectToOrder} 
             orderProducts={orderProducts}
             setOrderProducts={setOrderProducts} 
             units={units} 
             withGst={withGst} />
           </Grid>
           </div>
           <Grid container spacing={2}>
           <Grid item xs={12} sm={12} md={12} lg={12}>
             {
              orderProducts && orderProducts.length > 0  ? 
              orderProducts.map((e:any,index:number)=>{
                return <OrderProductBox key={`orderProductBox${index}`} product={e} 
                onQtyChange={(newQty:any) => updateOrderQty(index, newQty)}
                deleteOrderedProduct={deleteOrderedProduct} units={units}
                onUnitChange={onUnitChange} gstRates={gstRates} withGst={withGst}    onGstChange={onGstChange}
                onPriceChange={onPriceChange}
                 />
              })
              :null
             }
            </Grid>
            <Grid item xs={12} sm={3} md={3}> </Grid>
            <Grid item xs={12} sm={3} md={3}>
            <TextField
            fullWidth
                                    label="Vehicle No"
                                    type="text"
                                    size="small"
                                    value={vehicleNo}
                                    onChange={(e)=> setvehicleNo(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> </Grid>
            <Grid item xs={12} sm={3} md={2}> 
                <TextField
                                    label="Order Date"
                                    type="date"
                                    size="small"
                                    value={orderDate || ''}
                                    onChange={handleOrderDateChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /></Grid>
            <Grid item xs={12} sm={12} md={2}> <GstSwitch 
            withGst={withGst} 
            setWithGst={setWithGst}
            orderProducts={orderProducts}
            setOrderProducts={setOrderProducts}
            gstRates={gstRates}
            units={units}
            /> 
            
            </Grid>
            <Grid item  sm={12} md={2}>
            <Box sx={{textAlign:"right",marginBottom:"10px"}}>
            <FormControl fullWidth
            
            >
               <InputLabel>
               Status
              </InputLabel>
              <Select
              size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={(e)=> setStatus(e.target.value)}
              >
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Processing"}>Processing</MenuItem>
                <MenuItem value={"Shipped"}>Shipped</MenuItem>
                <MenuItem value={"Completed"}>Completed</MenuItem>
              </Select>
            </FormControl>
            </Box>
           
            </Grid>
        </Grid>
       
          <Box sx={{textAlign:"right",marginBottom:"10px"}}>
            {
              orderProducts && orderProducts.length > 0 ? 
                <>
                <Typography variant="body1" sx={{marginRight:"20x"}} >
                Qty: <b>
                { orderProducts.reduce((total: any, product: any) => {
    // Check if base_unit and unit are different
    const orderQty = product.attribute.base_unit !== product.attribute.unit ? 1 : Number(product.orderQty);
    return Number(total) + orderQty;
}, 0) }
                  </b>
 &nbsp; &nbsp;
                  Total: <b>{(orderProducts.reduce((total:any, product:any) => Number(total) + 
                (Number(product.attribute.price) * Number(product.orderQty)), 0) ).toFixed(2)}</b>
                              </Typography>
              
                </> : null
            }
            
          </Box>
            <Box sx={{textAlign:"center",marginBottom:"10px"}}>
              <label style={{color:"red"}}>{errorMessage}</label>
            </Box>

        <ButtonCust  sx={BUTTON_STYLE} onClick={createOrderFun}>
          Submit
        </ButtonCust> &nbsp;
        <ButtonCust  sx={CANCEL_BUTTON_STYLE} onClick={()=>{
           navigate('/admin/order_list');
        }}>
          Cancel
        </ButtonCust>
      </FormDiv>
      <DialogInvoice
      data={orderProducts} open={openInvoiceDailog} setOpen={setOpenInvoiceDailog}
      setOrderProducts={setOrderProducts}
      order={{...selectedUserId,orderId,orderDate,vehicleNo, totalPrice,roundOff}}
      />
      <Dialog
        open={openError}
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Product qty is out of stock.
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseError}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default OrderForm;
